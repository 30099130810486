import React, { createContext, useState, useContext, useEffect } from "react";
import { navigate } from "gatsby";

const LanguageContext = createContext("en");

export const LanguageProvider = ({ children }) => {
  const getDefaultLanguage = () => {
    if (typeof navigator === "undefined") return "en"; // check if the navigator object exists
    const browserLanguage = navigator.language.split("-")[0];
    return browserLanguage === "lt" ? "lt" : "en"; // Set to 'lt' if detected, otherwise 'en'
  };

  const [locale, setLocale] = useState(getDefaultLanguage());

  useEffect(() => {
    const path = typeof window !== "undefined" ? window.location.pathname : "";
    const pathParts = path.split("/").filter(Boolean); // Remove empty elements

    const detectedLocale = pathParts[0] === "lt" ? "lt" : "en";

    setLocale(detectedLocale);
  }, []);

  const switchLanguage = (language) => {
    setLocale(language);

    const path = typeof window !== "undefined" ? window.location.pathname : "";
    const hash = typeof window !== "undefined" ? window.location.hash : "";

    console.log(path);

    const pathParts = path.split("/").filter(Boolean); // Remove empty elements

    let newPath;

    if (language === "en") {
      // Remove the language part for English (default language)
      newPath = "/" + pathParts.slice(1).join("/");
    } else {
      // Add or replace the language part for other languages
      if (pathParts[0] === "lt") {
        newPath = "/" + [language, ...pathParts.slice(1)].join("/");
      } else {
        newPath = "/" + [language, ...pathParts].join("/");
      }
    }

    newPath += hash;

    navigate(newPath);
  };

  return (
    <LanguageContext.Provider value={{ locale, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
